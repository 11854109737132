function CleanPastedHTML (input) {
  // 1. remove line breaks / Mso classes
  var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
  var output = input.replace(stringStripper, ' ');
  // 2. strip Word generated HTML comments
  var commentSripper = new RegExp('<!--(.*?)-->', 'g');
  var output = output.replace(commentSripper, '');
  var tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi');
  // 3. remove tags leave content if any
  output = output.replace(tagStripper, '');
  // 4. Remove everything in between and including tags '<style(.)style(.)>'
  var badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript'];

  for (var i = 0; i < badTags.length; i++) {
    tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi');
    output = output.replace(tagStripper, '');
  }
  // 5. remove attributes ' style="..."'
  var badAttributes = ['style', 'start'];
  for (var i = 0; i < badAttributes.length; i++) {
    var attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"', 'gi');
    output = output.replace(attributeStripper, '');
  }
  return output;
}

export default function initMin () {
  return {
    height: 300,
    minHeight: null,
    maxHeight: null,
    fontNames: ['Futura LT W01 Medium', 'Futura LT W01 Bold', 'Futura LT W01 Light'],
    lang: 'de-DE',
    toolbar: [
      ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
      ['font', ['strikethrough', 'superscript', 'subscript']],
      ['color', ['color']],
      ['fontsize', ['fontsize']],
      ['height', ['height']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['link', 'video', 'table']],
      ['help', ['help']],
      ['fullscreen', ['fullscreen']],
      ['redo', ['redo']],
      ['undo', ['undo']],
      ['codeview', ['codeview']]
    ],
    fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36'],
    colors: [
      ['#000000', '#424242', '#636363', '#9C9C94', '#CEC6CE', '#EFEFEF', '#F7F7F7', '#FFFFFF'],
      ['#FF0000', '#FF9C00', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#9C00FF', '#FF00FF'],
      ['#F7C6CE', '#FFE7CE', '#FFEFC6', '#D6EFD6', '#CEDEE7', '#CEE7F7', '#D6D6E7', '#E7D6DE'],
      ['#E79C9C', '#FFC69C', '#FFE79C', '#B5D6A5', '#A5C6CE', '#9CC6EF', '#B5A5D6', '#D6A5BD'],
      ['#E76363', '#F7AD6B', '#FFD663', '#94BD7B', '#73A5AD', '#6BADDE', '#8C7BC6', '#C67BA5'],
      ['#CE0000', '#E79439', '#EFC631', '#6BA54A', '#4A7B8C', '#3984C6', '#634AA5', '#A54A7B'],
      ['#9C0000', '#B56308', '#BD9400', '#397B21', '#104A5A', '#085294', '#311873', '#731842'],
      ['#630000', '#7B3900', '#846300', '#295218', '#083139', '#003163', '#21104A', '#4A1031'],
      ['#007d39', '#007d39', '#007d39', '#f01921', '#f01921', '#f01921', '#555555', '#555555']
    ],
    callbacks: {
      onPaste: function (e) {
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        console.log(bufferText)
        document.execCommand('insertText', false, bufferText);
      }
    }
  }
}
