import $ from 'jquery'

export default function kdzSwitchBox (settings) {
  let options = {
    el: '.switchBox',
    box: '.boxContent',
    activeClass: 'active',
    attribute: 'data-box'
  }
  let config = $.extend(options, settings)

  $(document.body).on('click', config.el, function () {
    if (!$(this).hasClass(config.activeClass)) {
      $(config.el).removeClass(config.activeClass)
      $(this).addClass(config.activeClass)
      let boxID = $(this).attr(config.attribute)
      $(config.box).hide()
      $('#' + boxID).fadeIn()
    }
  })
}
