import $ from 'jquery'
var msgBoxTimeout = 5000
var _msgBox = $('#msgBox')

export function message (message = '', messageType = 'warning') {
  function msgBoxHide () {
    _msgBox.removeClass()
    _msgBox.addClass('msg-modal msg-hide')
  }

  function msgBoxShow (msg, alertType) {
    if (msg) {
      if (!alertType) {
        alertType = 'warning'
      }
      _msgBox.removeClass()
      _msgBox.addClass('msg-modal msg-' + alertType)
      _msgBox.html(msg)
      setTimeout(msgBoxHide, msgBoxTimeout)
    }
  }
  msgBoxShow(message, messageType)

  if (_msgBox.hasClass('set-message')) {
    var msg = _msgBox.attr('data-msg')
    var type = _msgBox.attr('data-type')
    msgBoxShow(msg, type)
  }
}

