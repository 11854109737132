import $ from 'jquery'

export function valueChange (event) {
  if (event.type === 'change' || event.keyCode === 13) {
    var aktVal = $(this).val()
    var aktID = $(this).attr('data-id')
    var table = $(this).attr('data-table')
    var spalteID = $(this).attr('data-spalte-id')
    var spalteVal = $(this).attr('data-spalte-val')
    var modulPath = 'scripts/'
    var modus = ''
    if ($(this).hasClass('valueChange')) {
      modus = 'valueChange'
    }
    if ($(this).hasClass('valueChangeRF')) {
      modus = 'valueChangeRF'
    }

    var submitData = 'aktID=' + aktID + '&aktVal=' + aktVal + '&table=' + table + '&spalteID=' + spalteID + '&spalteVal=' + spalteVal + '&modus=' + modus + '&live=1&modulPfad=' + modulPath

    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'html',
      data: submitData,
      success: function (data) {
        $('#' + aktID).css('color', '#f00')
      }
    })
    event.stopImmediatePropagation()
    return false
  }
}

export function setStatus (event) {
  event.preventDefault()

  var stat = $(this).text()
  var submitString = $(this).attr('rel')
  console.log(submitString)
  var elemente = submitString.split(';')
  var spaltenname = elemente[ 3 ] // Spaltenname
  var aktID = elemente[ 0 ] // Spaltenname
  var modulPath = 'scripts/'
  var submitData = 'werte=' + submitString + '&stat=' + stat + '&live=1&modulPfad=' + modulPath

  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'html',
    data: submitData,
    success: function (data) {
    }
  })

  // Zeilenfarbe ändern
  if (spaltenname === 'kkGelesen') {
    if (stat === 'ja') {
      $('.kkBox' + aktID).css('backgroundColor', '#992e40')
      $('#kkBox' + aktID).css('backgroundColor', '#992e40')
    } else {
      $('.kkBox' + aktID).css('backgroundColor', '#388c41')
      $('#kkBox' + aktID).css('backgroundColor', '#388c41')
    }
  }
  if (stat === 'ja') {
    $(this).text('nein')
  } else {
    $(this).text('ja')
  }

  return false
}
