import $ from 'jquery'
import vorlagenEditor from './vorlagenEditor'
import kdzSubmit from './forms'

function submitMail () {
  kdzSubmit({
    element: '.submitMailAjax'
  }, function (data, _form) {
    var modal = _form.closest('.modal')
    modal.modal('hide')
    setTimeout(function () {
      modal.remove()
    }, 500);
  })
}
export default function sendMailModal (el, settings) {
  let defaultSettings = {
    modulPfad: '',
    modus: 'sendMailModal'
  }
  let config = $.extend(defaultSettings, settings)
  $(document.body).on('click', el, function (evt) {
    evt.preventDefault();
    let configTmp = this.dataset /*$(this).data()*/
    let _this = $(this)
    _this.attr('disabled',true)
    $.extend(config, configTmp  );
   $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: config,
      success: function (data) {
        var _modal = $('#'+config.modalId)
        if(_modal.length > 0) {
          _modal = $('#'+config.modalId)
          _modal.modal('hide')
          setTimeout(function(){
            _modal = $('#'+config.modalId)
            _modal.remove()

            $(document.body).append(data.html)
            _modal = $('#'+config.modalId)
            _modal.modal({
              backdrop: false,
              keyboard: false,
              show: true
            })
            submitMail()
            vorlagenEditor('.modalTextArea', data.textTemplates)
            _modal.find('.modal-dialog').css({
              top: '0',
              left: '40%'
            });
            _modal.find('.modal-dialog').draggable({
              handle: ".modal-header"
            });

          }, 500);
        } else {
          $(document.body).append(data.html)
          _modal = $('#'+config.modalId)
          _modal.modal({
            backdrop: false,
            keyboard: false,
            show: true
          })
          submitMail()
          vorlagenEditor('.modalTextArea',data.textTemplates)
          _modal.find('.modal-dialog').css({
            top: '0',
            left: '40%'
          });
          _modal.find('.modal-dialog').draggable({
            handle: ".modal-header"
          });
        }
      },
      complete: function () {
        _this.attr('disabled',false)
      }
    })
    return false;
  })
  $(document.body).on('click', '.kdzCloseModal', function (evt) {
    let modal = $(this).closest('.modal')
    modal.modal('hide')
    setTimeout(function(){ modal.remove() }, 500);
  })

}
