import $ from 'jquery'
import * as message from './message'

function addNewData () {
  var modus = 'addNewData'
  var checked = $(this).is(':checked')
  var submitData = 'modus=' + modus + '&checked=' + checked

  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {
      console.log(data)
    }
  })
}

function saveAndStay () {
  $(this).append('<input type="hidden" name="submitAndStay" value="1" />')
  $(this).closest('form').submit()
  return false
}

function datasetDelete () {
  var id = $(this).attr('data-id')
  var table = $(this).attr('data-table')
  var idSpalte = $(this).attr('data-idSpalte')
  var modus = 'datasetDelete'
  var submitData = 'modus=' + modus + '&id=' + id + '&table=' + table + '&idSpalte=' + idSpalte

  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {
      // $('#konfig_' + konfigID).remove()
    }
  })

  return false
}

function isValidEmailAddress (emailAddress) {
  var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i) // eslint-disable-line
  return pattern.test(emailAddress)
}

function formCheckRequireFields (event) {
  var aktElement = $(this)
  var pflichtLabelFor = []
  var pflichtLabelText = []
  var fehler = 0
  var mailfehler = false
  var fehlerFelder = ''
  var fehlerText = ''
  var firstErrorField = ''

  // Alle Pflichtfeld-Labels durchlaufen
  var aktFields = aktElement.find('label.required:visible')
  aktFields.each(function (index, element) {
    pflichtLabelFor = $(this).attr('for')
    pflichtLabelText = $(this).text()
    $('#' + pflichtLabelFor).removeClass('errorClass')
    $('[for=' + pflichtLabelFor + ']').removeClass('errorClass')

    // Prüfen ob die dazugehörigen Inputs leer sind ,bzw nur die Texte der Labels enthalten
    if ($('#' + pflichtLabelFor).hasClass('jq-mail') && isValidEmailAddress($('#' + pflichtLabelFor).val()) === false) {
      if (fehler === 0) {
        firstErrorField = pflichtLabelFor
      }

      fehler++
      mailfehler = true
      fehlerFelder += pflichtLabelText + ', '

      $('#' + pflichtLabelFor).addClass('errorClass')
      $('[for=' + pflichtLabelFor + ']').addClass('errorClass')
    } else if ($('#' + pflichtLabelFor).val() !== pflichtLabelText && $('#' + pflichtLabelFor).val() === '') {
      if (fehler === 0) {
        firstErrorField = pflichtLabelFor
      }

      fehler++
      fehlerFelder += pflichtLabelText + ', '

      $('#' + pflichtLabelFor).addClass('errorClass')
      $('[for=' + pflichtLabelFor + ']').addClass('errorClass')
    }
  })

  if (fehler > 0) {
    event.preventDefault()
    fehlerFelder = fehlerFelder.substr(0, fehlerFelder.length - 2)

    if (mailfehler) {
      fehlerText = '<p>ungültige E-Mail Adresse</p>'
    } else {
      fehlerText = '<p>Bitte alle markierten Felder ausfüllen!<br><br><strong>' + fehlerFelder + '</strong></p>'
    }

    // Zur Meldung scrollen
    var errorPos = $('.errorClass').offset()
    errorPos = errorPos.top - 180
    $('html, body').animate({ scrollTop: errorPos }, 'slow')

    // Focus setzen
    $('#' + firstErrorField).select()

    // Meldung
    message.message(fehlerText, 'danger')

    event.stopPropagation()
    return false
  }
}

function checkSystemModulInputs () {
  $('#sysModInclude1').prop('disabled', $('#sysModStandard').is(':checked'))
  $('#sysModInclude2').prop('disabled', $('#sysModStandard').is(':checked'))
  $('#sysModInclude3').prop('disabled', $('#sysModStandard').is(':checked'))
  $('#sysModInclude4').prop('disabled', $('#sysModStandard').is(':checked'))
  $('#sysModBox1').prop('disabled', $('#sysModStandard').is(':checked'))
  $('#sysModBox2').prop('disabled', $('#sysModStandard').is(':checked'))
  $('#sysModBoxTitel1a').prop('disabled', $('#sysModStandard').is(':checked'))
  $('#sysModBoxTitel1b').prop('disabled', $('#sysModStandard').is(':checked'))
  $('#sysModBoxTitel2').prop('disabled', $('#sysModStandard').is(':checked'))
}

function menuEbenen () {
  if ($('#formMenu, #formKategorie, #formMenuBackend').length > 0) {
    var _menuInsert = $('#mainMenuInsert')
    // Wenn Nichts oder Ebene 1 gewählt ist ausblenden
    if ($('#menuEbene option:selected').val() === '0' || $('#menuEbene option:selected').val() === '1') {
      _menuInsert.hide()
    }
    $('#menuEbene').change(function () {
      var optionSelect = $('#menuEbene option:selected').val()
      if (optionSelect !== '0' && optionSelect !== '1') {
        var aktForm = $(this).attr('data-form-id')
        var aktEbene = optionSelect
        var modus = 'getMenuEbene'
        var modulPfad = 'scripts/'
        var submitData = 'modus=' + modus + '&aktEbene=' + aktEbene + '&aktForm=' + aktForm + '&modulPfad=' + modulPfad
        $.ajax({
          url: 'ajax.php',
          type: 'POST',
          dataType: 'html',
          data: submitData,
          success: function (data) {
            _menuInsert.empty()
            $(data).appendTo('#mainMenuInsert')
            _menuInsert.fadeIn()
          }
        })
      } else {
        _menuInsert.fadeOut()
      }
    })
  }
}
function checkAllCheckboxesX () {
  if (!$(this).prop('checked')) {
    $(this).parents('.box-body ').find('.check-all, .check-all-item').prop('checked', $(this).prop('checked'))
  }
}

function checkAllCheckboxes () {
  console.log('Tesat')
  $(this).parents('.box-body ').find('.check-all-item').prop('checked', $(this).prop('checked'))
}

function checkAllCheckboxesinSelected () {
  console.log($(this))
  $(this).parents('.checkbox-selected').find('.checkbox-all-item').prop('checked', $(this).prop('checked'))
}

function checkMainCheckbox () {
  var items = $(this).parents('.box-body ').find('.check-all-item').length
  var checkedItems = $(this).parents('.box-body ').find('.check-all-item:checked').length
  var notChecked = items - checkedItems
  $(this).parents('.box-body ').find('.check-all').prop('checked', notChecked === 0)
}

function checkCheckboxes () {
  $('.box-body ').each(function (index, element) {
    if ($(element).find('.check-all-item').length > 0) {
      var items = $(element).find('.check-all-item').length
      var checkedItems = $(element).find('.check-all-item:checked').length
      var notChecked = items - checkedItems
      $(element).find('.check-all').prop('checked', notChecked === 0)
    }
  })
}

function copyHtml () {
  var html = $(this).siblings('.htmlCodeGet').html()
  $(this).siblings('.htmlCodeInsert').append(html)
  return false
}
function getOverlay () {
  var modus = 'getoverlay'
  var path = $(this).attr('data-path')
  var overlayID = $(this).attr('data-overlayID')
  var id = $(this).attr('data-id')
  var submitData = 'modus=' + modus + '&path=' + path + '&id=' + id
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {
      $('#' + overlayID).remove()
      $('body').append(data[ 'html' ])
      $('#' + overlayID).modal('show')
    }
  })

  return false
}
export default function init () {
  $(document).ready(function () {
    $('#addNewDatas').on('click', addNewData)
    $('#submitAndStay').on('click', saveAndStay)
    $('.datasetDelete').on('click', datasetDelete)
    $('form[name=formContent]').on('submit', formCheckRequireFields)
    $('#sysModStandard').on('click', checkSystemModulInputs)
    $(document.body).on('click', '.check-all-x', checkAllCheckboxesX)
    $(document.body).on('click', '.check-all', checkAllCheckboxes)
    $(document.body).on('click', '.check-all-item', checkMainCheckbox)
    $(document.body).on('click', '.checkbox-all-selected', checkAllCheckboxesinSelected)
    $(document.body).on('click', '.htmlCodeAdd', copyHtml)
    checkCheckboxes()
    checkSystemModulInputs()
    menuEbenen()
    $(document).on('click', '.getoverlay', getOverlay)
  })
}
