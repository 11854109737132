import $ from 'jquery'
import kdzDeleteRow from './deleteRow'

export default function loadProjektFiles (settings) {
  let defaultSettings = {
    modulPfad: 'plugins/mod_planung/projekte/',
    modus: 'loadProjektFiles',
    table: '#projekt-files-table',
    contentId: 0
  }
  let config = $.extend(defaultSettings, settings)

  config.contentId = $(config.table).attr('data-id')
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'html',
    data: config,
    success: function (data) {
      $(config.table + ' tbody').html('')
      $(config.table + ' tbody').append(data)
      kdzDeleteRow()
    }
  })
}
