import $ from 'jquery';
import * as message from './message';

export default function kdzDeleteRow (settings, callback) {
  let options = {
    el: '.deleteContent'
  };
  let config = $.extend(options, settings);
  let place = 'right';
  if (config.placement) {
    let place = config.placement;
  }
  $(config.el).each(function () {
    $(this).confirmation('destroy');
    $(this).confirmation({
      title: 'Soll der Datensatz gelöscht werden?',
      placement: 'left',
      btnOkLabel: 'Ja',
      btnCancelLabel: 'Nein'
    }).on('confirmed.bs.confirmation', function (evt) {
      let _this = $(this);
      config = $.extend(options, _this.data());
      $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
          id: _this.attr('data-id'),
          idSpalte: _this.attr('data-id-spalte'),
          modus: _this.attr('data-modus'),
          parent: _this.attr('data-parent'),
          table: _this.attr('data-table')
        },
        success: function (data) {
          if (data.error === true) {
            message.message('Beim Speichern ist ein Fehler aufgetreten, bitte versuchen Sie es noch einmal!', 'danger');
          } else {
            if (config.parent) {
              _this.closest(config.parent).remove();
            }
            message.message('Der Inhalt wurde erfolgreich gespeichert!', 'success');
          }
          if(typeof callback === 'function') {
            callback(data, _this);
          }
        }
      });
    });
  });
}
