import $ from 'jquery';

function countHours () {
  let _elems = $('.zeitenGesamt');
  let _gesamt = $('#wtHoursGesamt');
  let _gesamtOut = $('#wtHoursGesamt-out');
  let _overHours = $('#wtOverHours');
  let _overHoursOut = $('#wtOverHours-out');
  let hoursSoll = parseFloat($('#wtSoll').val());
  let hours = 0;
  if (!_elems.is('[readonly]')) {
    _elems.each(function () {
      let val = $(this).val();
      if (val) {
        val = parseFloat(val.replace(',', '.').replace(' ', ''));
        if (!isNaN(val)) {
          hours = hours + val;
        }
      }
    });
    _gesamt.val(hours);
    _gesamtOut.html(hours);
    let over = hours - hoursSoll;
    if (over < 0) {
      over = 0;
    }
    _overHours.val(over);
    _overHoursOut.html(over);
  }
}

function countAbloese () {
  let _elems = $('.ausloese-count');
  let _gesamt = $('#wtAusloeseGesamt');
  let gesamt = 0;
  _elems.each(function () {
    let val = $(this).val();

    if (val) {
      val = parseFloat(val.replace(',', '.').replace(' ', ''));
      if (!isNaN(val)) {
        gesamt = gesamt + val;
      }
    }
  });
  _gesamt.val(gesamt);

}

function calcHours () {
  let _this = $(this);
  let _parent = _this.closest('tr');
  let wtdTimeFrom = _parent.find('.wtdTimeFrom').val();
  let wtdTimeTo = _parent.find('.wtdTimeTo').val();
  let wtdTimeFrom2 = _parent.find('.wtdTimeFrom2').val();
  let wtdTimeTo2 = _parent.find('.wtdTimeTo2').val();
  let wtdPause = _parent.find('.wtdPause').val();
  let wtdDay = _parent.find('.wtdDay').val();
  if (!_parent.find('.wtdTimeFrom').is('[readonly]') && wtdTimeFrom !== '' && wtdTimeTo !== '') {
    var data = {
      wtdDay: wtdDay,
      wtdTimeFrom: wtdTimeFrom,
      wtdTimeTo: wtdTimeTo,
      wtdTimeFrom2: wtdTimeFrom2,
      wtdTimeTo2: wtdTimeTo2,
      wtdPause: wtdPause,
      modus: 'calcWorkingTime',
      modulPfad: 'plugins/mod_user/zeitenausgabe/'
    };
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: data,
      success: function (data) {
        _parent.find('.zeitenGesamt').val(data.time);
      }
    });
  }
}

function setAbloese () {
  let _parent = $(this).closest('tr');
  let _montage = _parent.find('.wtdMontage');
  let _fullDay = _parent.find('.wtdFullDay');
  let _abloeseFeld = _parent.find('.wtdAusloese');
  let abloese = 0;
  if (_montage.prop('checked')) {
    abloese = 12;
  }
  if (_fullDay.prop('checked')) {
    abloese += 12;
  }
  _abloeseFeld.val(abloese);
  countAbloese();

}

function saveSickDay () {
  let _this = $(this);
  let _parent = $(this).closest('tr');

  var data = {
    date: _this.attr('data-date'),
    userID: $('#mitarbeiter').val(),
    modus: 'saveSickDay',
    modulPfad: 'plugins/mod_user/'
  };
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: data,
    success: function (data) {
      if (data.isSick === true || data.isSick === 'ture') {
        _parent.find('.wtdTimeFrom').attr('readonly', 'readonly');
        _parent.find('.wtdTimeTo').attr('readonly', 'readonly');

        _parent.find('.wtdTimeFrom2').attr('readonly', 'readonly');
        _parent.find('.wtdTimeTo2').attr('readonly', 'readonly');

        _parent.find('.wtdPause').attr('readonly', 'readonly');

        _parent.find('.wtdDrivetime').attr('readonly', 'readonly').val('');
        _parent.find('.zeitenGesamt').attr('readonly', 'readonly').val(-8);
        _parent.find('.wtdCity').attr('readonly', 'readonly').val('');
        _parent.find('.wtdTask').attr('readonly', 'readonly').val('');
        _parent.find('.wtdMontage').prop('checked', false).attr('disabled', 'disabled');
        _parent.find('.wtdFullDay').prop('checked', false).attr('disabled', 'disabled');
        _parent.find('.wtdAusloese').attr('readonly', 'readonly').val(0);
        _parent.addClass('is--sickday');
      } else {
        _parent.find('.wtdTimeFrom').attr('readonly', null);
        _parent.find('.wtdTimeTo').attr('readonly', null);
        _parent.find('.wtdTimeFrom2').attr('readonly', null);
        _parent.find('.wtdTimeTo2').attr('readonly', null);
        _parent.find('.wtdPause').attr('readonly', null);
        _parent.find('.wtdDrivetime').attr('readonly', null);
        _parent.find('.zeitenGesamt').attr('readonly', null);
        _parent.find('.wtdCity').attr('readonly', null);
        _parent.find('.wtdTask').attr('readonly', null);
        _parent.find('.wtdMontage').attr('disabled', null);
        _parent.find('.wtdFullDay').attr('disabled', null);
        _parent.find('.wtdAusloese').attr('readonly', null);
        _parent.removeClass('is--sickday');
      }
      calcHours();
    }
  });
}

export default function init () {
  $(document).ready(function () {
    $(document.body).on('blur keyup', '.ausloese-count', countAbloese);
    $(document.body).on('blur keyup', '.zeitenGesamt', countHours);

    $(document.body).on('change', '.wtdTimeFrom', calcHours);
    $(document.body).on('change', '.wtdTimeTo', calcHours);

    $(document.body).on('change', '.wtdTimeFrom2', calcHours);
    $(document.body).on('change', '.wtdTimeTo2', calcHours);

    $(document.body).on('blur keyup', '.wtdPause', calcHours);

    $(document.body).on('input', '.calc-abloese', setAbloese);
    $(document.body).on('input', '.save-sick-day', saveSickDay);

    countHours();
  });
}
