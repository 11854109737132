import $ from 'jquery';
import * as message from './message';


export default function init () {
  $(document).ready(function () {
    $('#calender').fullCalendar({
      themeSystem: 'bootstrap3',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      defaultView: $(window).width() < 765 ? 'basicDay':'agendaWeek',
      weekNumbers: true,
      eventLimit: true, // allow "more" link when too many events
      locale: 'de',
      timeFormat: 'H:mm',
      slotLabelFormat: 'H:mm',
      allDayText: 'Ganzer Tag',
      buttonText: {
        today: 'Heute',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        list: 'Liste'
      },
      eventClick: function (calEvent, jsEvent, view) {
        if (calEvent.source.id === 'eventTermin') {
          $.ajax({
            url: 'ajax.php',
            type: 'POST',
            dataType: 'html',
            data: {
              modalTitel: 'Termin anlegen',
              modalId: 'modalTermin',
              modulPfad: 'plugins/mod_termin/',
              contentId: calEvent.id,
              modus: 'modalTermin'
            },
            success: function (data) {
              var _modal = $('#modalTermin');
              _modal.remove();
              if (_modal.length > 0) {
                _modal.modal('hide');
                setTimeout(function () {
                  _modal.remove();

                  $(document.body).append(data);
                  _modal.modal({
                    backdrop: false,
                    keyboard: false,
                    show: true
                  });

                  _modal.find('textarea').not('noEditor').summernote({
                    height: 300,
                    minHeight: null,
                    maxHeight: null,
                    lang: 'de-DE',
                    toolbar: [
                      ['style', ['bold', 'italic', 'underline']],
                      ['font', ['superscript', 'subscript']],
                      ['color', ['color']],
                      ['fontsize', ['fontsize']],
                      ['para', ['ul', 'ol', 'paragraph']],
                      ['insert', ['link', 'table']],
                      ['redo', ['redo']],
                      ['undo', ['undo']]
                    ],
                    fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36']
                  });
                  _modal.find('.modal-dialog').css({
                    top: '0',
                    left: '40%'
                  });
                  _modal.find('.modal-dialog').draggable({
                    handle: '.modal-header'
                  });

                }, 500);
              } else {
                $(document.body).append(data);
                _modal = $('#modalTermin');
                _modal.modal({
                  backdrop: false,
                  keyboard: false,
                  show: true
                });

                _modal.find('textarea').not('noEditor').summernote({
                  height: 300,
                  minHeight: null,
                  maxHeight: null,
                  lang: 'de-DE',
                  toolbar: [
                    ['style', ['bold', 'italic', 'underline']],
                    ['font', ['superscript', 'subscript']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['insert', ['link', 'table']],
                    ['redo', ['redo']],
                    ['undo', ['undo']]
                  ],
                  fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36']
                });
                _modal.find('.modal-dialog').css({
                  top: '0',
                  left: '40%'
                });
                _modal.find('.modal-dialog').draggable({
                  handle: '.modal-header'
                });
              }
            }
          });
        }


      },
      dayClick: function (date, jsEvent, view) {

        $.ajax({
          url: 'ajax.php',
          type: 'POST',
          dataType: 'html',
          data: {
            modalTitel: 'Termin anlegen',
            modalId: 'modalTermin',
            modulPfad: 'plugins/mod_termin/',
            contentId: '',
            terDateStart: date.format(),
            modus: 'modalTermin'
          },
          success: function (data) {
            var _modal = $('#modalTermin');
            _modal.remove();
            if (_modal.length > 0) {
              _modal.modal('hide');
              setTimeout(function () {
                _modal.remove();

                $(document.body).append(data);
                _modal.modal({
                  backdrop: false,
                  keyboard: false,
                  show: true
                });

                _modal.find('textarea').not('noEditor').summernote({
                  height: 300,
                  minHeight: null,
                  maxHeight: null,
                  lang: 'de-DE',
                  toolbar: [
                    ['style', ['bold', 'italic', 'underline']],
                    ['font', ['superscript', 'subscript']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['insert', ['link', 'table']],
                    ['redo', ['redo']],
                    ['undo', ['undo']]
                  ],
                  fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36']
                });
                _modal.find('.modal-dialog').css({
                  top: '0',
                  left: '40%'
                });
                _modal.find('.modal-dialog').draggable({
                  handle: '.modal-header'
                });

              }, 500);
            } else {
              $(document.body).append(data);
              _modal = $('#modalTermin');
              _modal.modal({
                backdrop: false,
                keyboard: false,
                show: true
              });

              _modal.find('textarea').not('noEditor').summernote({
                height: 300,
                minHeight: null,
                maxHeight: null,
                lang: 'de-DE',
                toolbar: [
                  ['style', ['bold', 'italic', 'underline']],
                  ['font', ['superscript', 'subscript']],
                  ['color', ['color']],
                  ['fontsize', ['fontsize']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['insert', ['link', 'table']],
                  ['redo', ['redo']],
                  ['undo', ['undo']]
                ],
                fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36']
              });
              _modal.find('.modal-dialog').css({
                top: '0',
                left: '40%'
              });
              _modal.find('.modal-dialog').draggable({
                handle: '.modal-header'
              });
            }
          }
        });

        //alert('Clicked on: ' + date.format());
      },
      eventSources: [

        // your event source
        {
          id: 'eventTermin',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getTermine'
          },
          color: '#605ca8',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        },
        {
          id: 'eventPlanung',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getPlanung'
          },
          color: '#3c8dbc',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        },
        {
          id: 'eventUrlaub',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getUrlaub'
          },
          color: '#29a800',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        },
        {
          id: 'eventAbsetzer',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getAbsetzter'
          },
          color: '#3c763d',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        },
        {
          id: 'eventKrank',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getKrank'
          },
          color: '#a89600',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        },
        {
          id: 'eventFeiertage',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getFeiertage'
          },
          backgroundColor: '#0da80f',
          rendering: 'background'
        }
      ],
      eventRender: function (event, element, view) {
        /* Only muck about if it is a background rendered event */
        if (event.rendering === 'background') {
          var bgEventTitle = document.createElement('div');
          bgEventTitle.style.position = 'absolute';
          bgEventTitle.style.bottom = '0';
          /* bgEventTitle.classList.add('fc-event'); */
          bgEventTitle.innerHTML = /*'<span class="fc-title">' + */ event.title /* + '</span>'*/;
          /* set container element positioning to relative so the positioning above will work */
          element.css('position', 'relative').html(bgEventTitle);
        }
      }
    });
    $(document.body).on('click', '#showProjekte', function () {
      if ($(this).prop('checked') === false) {
        $('#calender').fullCalendar('removeEventSource', 'eventPlanung');
      } else {
        $('#calender').fullCalendar('addEventSource', {
          id: 'eventPlanung',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getPlanung'
          },
          color: '#3c8dbc',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        });
      }
    });


    $(document.body).on('click', '#showTermine', function () {
      if ($(this).prop('checked') === false) {
        $('#calender').fullCalendar('removeEventSource', 'eventTermin');
      } else {
        $('#calender').fullCalendar('addEventSource', {
          id: 'eventTermin',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getTermine'
          },
          color: '#605ca8',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        });
      }
    });

    $(document.body).on('click', '#showUrlaub', function () {
      if ($(this).prop('checked') === false) {
        $('#calender').fullCalendar('removeEventSource', 'eventUrlaub');
      } else {
        $('#calender').fullCalendar('addEventSource', {
          id: 'eventUrlaub',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getUrlaub'
          },
          color: '#29a800',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        });
      }
    });

    $(document.body).on('click', '#showAbsetzer', function () {
      if ($(this).prop('checked') === false) {
        $('#calender').fullCalendar('removeEventSource', 'eventAbsetzer');
      } else {
        $('#calender').fullCalendar('addEventSource', {
          id: 'eventAbsetzer',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getAbsetzter'
          },
          color: '#3c763d',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        });
      }
    });

    $(document.body).on('click', '#showKrank', function () {
      if ($(this).prop('checked') === false) {
        $('#calender').fullCalendar('removeEventSource', 'eventKrank');
      } else {
        $('#calender').fullCalendar('addEventSource', {
          id: 'eventKrank',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getKrank'
          },
          color: '#a89600',   // a non-ajax option
          textColor: 'white' // a non-ajax option
        });
      }
    });

    $(document.body).on('click', '#showFeiertage', function () {
      if ($(this).prop('checked') === false) {
        $('#calender').fullCalendar('removeEventSource', 'eventFeiertage');
      } else {
        $('#calender').fullCalendar('addEventSource', {
          id: 'eventFeiertage',
          url: 'ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            modulPfad: 'plugins/mod_termin/',
            modus: 'getFeiertage'
          },
          backgroundColor: '#0da80f',
          rendering: 'background'
        });
      }
    });

    const element = document.getElementById('calender');
    const scrollableBody = element.querySelector('.fc-content-skeleton');
    const scrollableHeader = element.querySelector('.fc-head-container > .fc-row');
    const scrollableBg = element.querySelector('.fc-bg');
    scrollableBody.addEventListener('scroll', () => {
      scrollableHeader.scrollTo(scrollableBody.scrollLeft, 0);
      scrollableBg.scrollTo(scrollableBody.scrollLeft, 0);
    });
  });
}
