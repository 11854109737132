import $ from 'jquery'

export default function kdzModalLoader (el, settings) {
  let defaultSettings = {
    modulPfad: '',
    modus: 'loadModal'
  }
  let configTmp = $.extend(defaultSettings, settings)
  $(document.body).on('click', el, function (evt) {
    evt.preventDefault();
    let config = $(this).data()
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'html',
      data: config,
      success: function (data) {
        var _modal = $('#'+config.modalId)
        if(_modal.length > 0) {
          _modal = $('#'+config.modalId)
          _modal.modal('hide')
          setTimeout(function(){
            _modal = $('#'+config.modalId)
            _modal.remove()

            $(document.body).append(data)
            _modal = $('#'+config.modalId)
            _modal.modal({
              backdrop: false,
              keyboard: false,
              show: true
            })

            _modal.find('textarea').not('noEditor').summernote({
              height: 300,
              minHeight: null,
              maxHeight: null,
              lang: 'de-DE',
              toolbar: [
                [ 'style', [ 'bold', 'italic', 'underline' ] ],
                [ 'font', ['superscript', 'subscript' ] ],
                [ 'color', [ 'color' ] ],
                [ 'fontsize', [ 'fontsize' ] ],
                [ 'para', [ 'ul', 'ol', 'paragraph' ] ],
                [ 'insert', [ 'link','table' ] ],
                [ 'redo', [ 'redo' ] ],
                [ 'undo', [ 'undo' ] ]
              ],
              fontSizes: [ '8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36' ]
            })
            _modal.find('.modal-dialog').css({
              top: '0',
              left: '40%'
            });
            _modal.find('.modal-dialog').draggable({
              handle: ".modal-header"
            });

          }, 500);
        } else {
          $(document.body).append(data)
          _modal = $('#'+config.modalId)
          _modal.modal({
            backdrop: false,
            keyboard: false,
            show: true
          })

          _modal.find('textarea').not('noEditor').summernote({
            height: 300,
            minHeight: null,
            maxHeight: null,
            lang: 'de-DE',
            toolbar: [
              [ 'style', [ 'bold', 'italic', 'underline' ] ],
              [ 'font', ['superscript', 'subscript' ] ],
              [ 'color', [ 'color' ] ],
              [ 'fontsize', [ 'fontsize' ] ],
              [ 'para', [ 'ul', 'ol', 'paragraph' ] ],
              [ 'insert', [ 'link','table' ] ],
              [ 'redo', [ 'redo' ] ],
              [ 'undo', [ 'undo' ] ]
            ],
            fontSizes: [ '8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36' ]
          })
          _modal.find('.modal-dialog').css({
            top: '0',
            left: '40%'
          });
          _modal.find('.modal-dialog').draggable({
            handle: ".modal-header"
          });
        }
      }
    })
    return false;
  })
  $(document.body).on('click', '.kdzCloseModal', function (evt) {
    let modal = $(this).closest('.modal')
    modal.modal('hide')
    setTimeout(function(){ modal.remove() }, 500);
  })

}
