import $ from 'jquery'

function panelDisplay (event) {
  event.preventDefault()
  let aktPanel = $(this).attr('data-panel')

  $('.panel-form').each(function () {
    if (!$(this).hasClass(aktPanel)) {
      $(this).hide()
    }
  })
  $('.jq-nav-form-control').each(function () {
    $(this).closest('li').removeClass('active')
  })
  $(this).closest('li').addClass('active')
  $('.' + aktPanel).show()
  $('#activePanel').val(aktPanel)
  window.location.hash = '#' + aktPanel
  return false
}

function panelDisplayInit (event) {
  let aktPanel = location.hash.substring(1)
  if (aktPanel === '') {
    aktPanel = $('#activePanel').val()
  }

  if (aktPanel === '') {
    let anchor = location.hash.substring(1)
    if (anchor === '') {
      $('.jq-nav-form-control').each(function () {
        if ($(this).closest('li').hasClass('active')) {
          aktPanel = $(this).attr('data-panel')
        }
      })
    } else {
      $('.jq-nav-form-control').each(function () {
        $(this).closest('li').removeClass('active')
      })
      $('a[data-panel=\'' + anchor + '\']').closest('li').addClass('active')
      aktPanel = anchor
    }
  } else {
    $('.jq-nav-form-control').each(function () {
      $(this).closest('li').removeClass('active')
    })
    $('a[data-panel=\'' + aktPanel + '\']').closest('li').addClass('active')
  }

  $('.panel-form').each(function () {
    if (!$(this).hasClass(aktPanel)) {
      $(this).hide()
    }
  })
}

export default function init () {
  panelDisplayInit()
  $(document.body).on('click', '.jq-nav-form-control', panelDisplay)
}
