import $ from 'jquery'

$(function(){
  $('.password-toggle').click(function(){
    if( $(this).hasClass('fa-eye') ) {
      $('input[name="passwort"]').attr('type','text')
      $(this).removeClass('fa-eye')
      $(this).addClass('fa-eye-slash')
    } else {
      $('input[name="passwort"]').attr('type','password')
      $(this).addClass('fa-eye')
      $(this).removeClass('fa-eye-slash')
    }
  });

  $('form button[type="submit"]').on('click', function(){
    $('.password-toggle').addClass('fa-eye')
    $('.password-toggle').removeClass('fa-eye-slash')
    $('.hide-show').parent().find('input[name="passwort"]').attr('type','password')
  });
});