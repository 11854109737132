import $ from 'jquery'
let modulPfadFibu = 'plugins/mod_fibu/'
let ctextTemplates
if (typeof textTemplates !== 'undefined') {
  ctextTemplates = textTemplates
}
function loadVorlagenTexte ($typ, callback, elm) {
  var config = {
    modus: 'loadVorlagenTexte',
    modulPfad: modulPfadFibu,
    typ: $typ
  }
  if(elm !== undefined) {
    $.extend( config, elm.data() );
  }
  console.log(config)
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: config,
    success: function (data) {
      callback(data)
    }
  })
}

function vorlagenText (context) {
  var ui = $.summernote.ui;
  // create button
  //console.log($(context.layoutInfo.note).closest('.box-body').attr('data-position'))
  if (typeof ctextTemplates === 'undefined' && typeof textTemplates !== 'undefined') {
    ctextTemplates = textTemplates
  }
  var event = ui.buttonGroup([
    ui.button({
      contents: 'Textvorlagen <i class="fa fa-caret-down" aria-hidden="true"></i>',
      tooltip: 'Laden sie vorgerfertigte Texte in den Editor',
      data: {
        toggle: 'dropdown'
      }
    }),
    ui.dropdown({
      items: ctextTemplates,
      click: function (e) {
        var $button = $(e.target);
        var value = $button.data('value');
        if($button.closest('.is--render-text')) {
          var elm = $button.closest('.is--render-text')
        } else {
          var elm
        }
        e.preventDefault();
        loadVorlagenTexte(value, function (data) {
          context.layoutInfo.note.summernote('code', data.temText);
        }, elm)
      }
    })
  ]);

  return event.render();   // return button as jquery object
}

export default function initFibuEditor (el, tetextTemplates) {
  ctextTemplates = tetextTemplates;
  var editor
  if(el) {
    editor = $(el)
  } else {
    editor = $('textarea.editor-fibu-text')
  }

  editor.summernote({
    height: 300,
    minHeight: null,
    maxHeight: null,
    lang: 'de-DE',
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['strikethrough', 'superscript', 'subscript']],
      ['color', ['color']],
      ['fontsize', ['fontsize']],
      ['height', ['height']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['table']],
      ['fullscreen', ['fullscreen']],
      ['redo', ['redo']],
      ['undo', ['undo']],
      ['codeview', ['codeview']],
      ['eventButton', ['event']]
    ],
    fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36'],
    colors: [
      ['#000000', '#424242', '#636363', '#9C9C94', '#CEC6CE', '#EFEFEF', '#F7F7F7', '#FFFFFF'],
      ['#FF0000', '#FF9C00', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#9C00FF', '#FF00FF'],
      ['#F7C6CE', '#FFE7CE', '#FFEFC6', '#D6EFD6', '#CEDEE7', '#CEE7F7', '#D6D6E7', '#E7D6DE'],
      ['#E79C9C', '#FFC69C', '#FFE79C', '#B5D6A5', '#A5C6CE', '#9CC6EF', '#B5A5D6', '#D6A5BD'],
      ['#E76363', '#F7AD6B', '#FFD663', '#94BD7B', '#73A5AD', '#6BADDE', '#8C7BC6', '#C67BA5'],
      ['#CE0000', '#E79439', '#EFC631', '#6BA54A', '#4A7B8C', '#3984C6', '#634AA5', '#A54A7B'],
      ['#9C0000', '#B56308', '#BD9400', '#397B21', '#104A5A', '#085294', '#311873', '#731842'],
      ['#630000', '#7B3900', '#846300', '#295218', '#083139', '#003163', '#21104A', '#4A1031']
    ],
    buttons: {
      event: vorlagenText
    }
  })
}