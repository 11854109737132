import $ from 'jquery';

export default () => {
  function changVacationStats () {
    var _this = $(this);
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        vacID: _this.attr('data-id'),
        content: _this.val(),
        modus: 'changeVactionStats'

      },
      success: function (data) {
        console.log(data, typeof data.error, _this.val(), typeof _this.val());
        if (data.error === false) {
          if (_this.val() === '1' || _this.val() === '2') {
            _this.closest('tr').remove();
          }
        }
      }
    });
  }
  function changVacationStatsSimple () {
    var _this = $(this);
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        vacID: _this.attr('data-id'),
        content: _this.val(),
        modus: 'changeVactionStats'

      },
      success: function (data) {
        console.log(data);
      }
    });
  }

  $(document.body).on('change', '.changeVactionStats', changVacationStats);
  $(document.body).on('change', '.changeVactionStatsSimple', changVacationStatsSimple);
};
