/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import $ from 'jquery';
import 'bootstrap';
import Dropzone from 'dropzone';
import './modules/skin';
import './vendor/bootstrap-confirmation';
import './modules/passwort';
import editorInit from './modules/editor';
import * as message from './modules/message';
import * as overview from './modules/overview-functions';
import contentInit from './modules/content';
import kdzModalLoader from './modules/modal';
import kdzSubmit from './modules/forms';
import kdzSaveField from './modules/saveField';
import kdzSwitchBox from './modules/switchBox';
import kdzDeleteRow from './modules/deleteRow';
import loadProjektFiles from './modules/loadProjektFiles';
import kdzMitarbeiterPlanung from './modules/mitarbeiter-planung';
import kdzTermin from './modules/termin';
import anfrageInit from './modules/anfragen';
import sendMailModal from './modules/sendMail';
import notizenInit from './modules/notizen';
import terminInit from './modules/calender';
import arbeitZeiten from './modules/arbeitszeiten';
import panelInit from './modules/panel';
import tabelInit from './modules/tables';
import workingtimesInit from './modules/zeitenerfassung';
import initHalle from './modules/hallen';
import vacation from './modules/vacation';
Dropzone.autoDiscover = false;

$(document).ready(function () {
  if ($('#fileUplodDrop').length) {
    var myDropzone = new Dropzone('#fileUplodDrop', {
      url: 'fileupload.php?contentModul=' + $('#fileUplodDrop').attr('data-modul') + '&contentID=' + $('#fileUplodDrop').attr('data-id'),
      timeout: 100000
    });
    myDropzone.on('success', function (file) {
      const modul = $('#fileUplodDrop').attr('data-modul');
      if (modul === 'user') {
        loadProjektFiles({
          modulPfad: 'plugins/mod_user/mitarbeiter/',
          modus: 'loadUserFiles',
          table: '#user-files-table'
        });
      } else if (modul === 'halle') {
        loadProjektFiles({
          modulPfad: 'plugins/mod_halle/',
          modus: 'loadUserFiles',
          table: '#halle-files-table'
        });
      } else {
        loadProjektFiles();
      }
    });
  }
  panelInit();
  kdzModalLoader('.addCustomer');
  kdzSaveField('.saveField');
  kdzSubmit({}, function () {
  });
  kdzSwitchBox();

  kdzMitarbeiterPlanung();
  kdzTermin();
  anfrageInit();
  notizenInit();
  arbeitZeiten();
  sendMailModal('.sendMail');
  tabelInit();
  initHalle();
  kdzDeleteRow();
  workingtimesInit();
  if ($('#calender').length) {
    terminInit();
  }
  vacation();
  $('.valueChange').on('change', overview.valueChange);
  $('.valueChangeRF').on('change', overview.valueChange);
  $('.valueChange').on('keypress', overview.valueChange);
  $('.valueChangeRF').on('keypress', overview.valueChange);
  $('.datepicker').datepicker({
    dateFormat: 'yy-mm-dd'
  });
  $(document.body).on('click', '.setStatus', overview.setStatus);
  contentInit();
  message.message();
  let editorConfig = editorInit();
  var editor = $('textarea').not('.noEditor');
  editor.summernote(editorConfig);
  editor.addClass('noEditor');

  autosize(document.querySelectorAll('textarea.is--autosize'));

  $('#free-draggable').draggable();
});
