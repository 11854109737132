import $ from 'jquery';
import * as message from './message';
import kdzSubmit from './forms';
import kdzModalLoader from './modal';

let modulPfadTime = 'plugins/mod_user/arbeitszeit/';

function loadAufagben () {
  let proID = $(this).val();
  let modus = 'loadAufagben';
  let submitData = 'modus=' + modus +
    '&proID=' + proID +
    '&modus=' + modus +
    '&modulPfad=' + modulPfadTime;
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {
      $('#insert--aufgaben').html(data.html).removeClass('hidden');
    }
  });
}

function loadAufagbenHeader () {
  let proID = $(this).val();
  let modus = 'loadAufagben';
  let submitData = 'modus=' + modus +
    '&proID=' + proID +
    '&modus=' + modus +
    '&modulPfad=' + modulPfadTime;
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {
      $('#insert--aufgaben-header').html(data.html).removeClass('hidden');
      $('#showTimerSettings').removeClass('hidden');
    }
  });
}

function startTimer () {
  let utProjekt_FK = $('#formTimerGlobal #utProjekt_FK-header').val();
  let utAufgaben_FK = $('#formTimerGlobal #utAufgaben_FK-header').val();
  let utInfo = $('#formTimerGlobal #utInfo-header').val();
  let modusTimerDS = $('#formTimerGlobal #modusTimerDS').val();
  let utID = $('#formTimerGlobal #utID-header').val();
  let utConfirmed = $('#formTimerGlobal #utConfirmed-header').is('checked');

  if (utProjekt_FK !== '') {
    let modus = 'startTimer';
    let submitData = 'modus=' + modus +
      '&utProjekt_FK=' + utProjekt_FK +
      '&utAufgaben_FK=' + utAufgaben_FK +
      '&utInfo=' + utInfo +
      '&modusDS=' + modusTimerDS +
      '&utID=' + utID +
      '&utConfirmed=' + utConfirmed +
      '&modus=' + modus +
      '&utModul=projekt' +
      '&modulPfad=' + modulPfadTime;
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: submitData,
      success: function (data) {
        $('#stoptimer').attr('data-id', data.id)
          .attr('data-time', 0)
          .timer('remove')
          .timer({
            seconds: 0
          });
        $('#timer-header-titel').html(data.title);
        $('#timer-header').removeClass('hidden');
        $('#timer-form-header').addClass('hidden');
        $('#saveTime').attr('data-content-id', data.id).hide();
      }
    });
  } else {
    message.message('Bitte wählen Sie erst ein Bauvorhaben!', 'danger');
  }
}

function startTimerButton () {
  const _this = $(this);
  const utProjekt_FK = _this.attr('data-project');
  const utAufgaben_FK = _this.attr('data-aufgabe');
  const modusDS = 'new';
  const modus = 'startTimer';
  const submitData = 'modus=' + modus +
    '&utProjekt_FK=' + utProjekt_FK +
    '&utAufgaben_FK=' + utAufgaben_FK +
    '&modusDS=' + modusDS +
    '&modus=' + modus +
    '&utModul=halle' +
    '&modulPfad=' + modulPfadTime;
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {
      $('#stoptimer')
        .attr('data-id', data.id)
        .attr('data-time', 0)
        .timer('remove')
        .timer({
          seconds: 0
        });
      $('#timer-header-titel').html(data.title);
      $('#timer-header').removeClass('hidden');
      $('#timer-form-header').addClass('hidden');
      $('#saveTime').attr('data-content-id', data.id).hide();
    }
  });
}

function stopTimer () {
  let _timer = $('#stoptimer');
  _timer.timer('pause');
  let modus = 'stopTimer';
  let utID = _timer.attr('data-id');
  let submitData = 'modus=' + modus +
    '&modulPfad=' + modulPfadTime +
    '&utID=' + utID;
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {
    }
  });
  $('#saveTime').show();
}

function restartTimer () {
  let _timer = $('#stoptimer');
  _timer.timer('resume');
  let modus = 'restartTimer';
  let utID = _timer.attr('data-id');
  let submitData = 'modus=' + modus +
    '&modulPfad=' + modulPfadTime +
    '&utID=' + utID;
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {

    }
  });
  $('#saveTime').hide();
}


export default function init () {
  $(document).ready(function () {
    $(document.body).on('change', '#utProjekt_FK', loadAufagben);
    $(document.body).on('change', '#utProjekt_FK-header', loadAufagbenHeader);
    $(document.body).on('click', '#startTime', startTimer);
    $(document.body).on('click', '#stopTime', stopTimer);
    $(document.body).on('click', '#restartTime', restartTimer);

    $(document.body).on('click', '.startTimerButton', startTimerButton);

    kdzSubmit({
      element: '.submitAjaxTimer'
    }, function (data, _form) {
      $('#timer-header').addClass('hidden');
      $('#timer-form-header').removeClass('hidden');
      var modal = _form.closest('.modal');
      modal.modal('hide');
      setTimeout(function () {
        modal.remove();
      }, 500);

    });
  });
}
