import $ from 'jquery';
import * as message from '../modules/message';

let modulPfadAnfrage = 'plugins/mod_halle/';

function addWare () {
  let lists = $('.waren-list');
  let notSaved = false;
  lists.each(function () {
    if ($(this).hasClass('not-saved')) {
      notSaved = true;
    }
  });
  let fibuHash = $(this).attr('data-id');
  let modus = 'addWare';
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: {
      'modus': modus,
      'fibuHash': fibuHash,
      'modulPfad': modulPfadAnfrage
    },
    success: function (data) {
      $('#waren-list-body').append(data);
      initListeEvents();
      autosize(document.querySelectorAll('textarea.is--autosize'));
    }
  });
}

function saveWare () {
  let _this = $(this);
  let row = _this.attr('data-id');
  let fibuHash = _this.attr('data-fibuHash');
  let fgText = $('#fgText-' + row).val();
  let fgCount = $('#fgCount-' + row).val();
  let fgNumber = $('#fgNumber-' + row).val();
  let fgInternVermerk = $('#fgInternVermerk-' + row).val();
  let fgID = $('#fgID-' + row).val();
  if (fgText !== '') {
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        'modus': 'saveWare',
        'fgText': fgText,
        'fibuHash': fibuHash,
        'fgCount': fgCount,
        'fgNumber': fgNumber,
        'fgInternVermerk': fgInternVermerk,
        'fgID': fgID,
        'modulPfad': modulPfadAnfrage
      },
      success: function (data) {
        if (data.error === false) {
          message.message('Der Eintrag wurde erfolgreich gespeichert', 'success');
          $('#waren-list-' + row).removeClass('not-saved');
        } else {
          message.message('Beim Speichern ist ein Fehler aufgetreten', 'danger');
        }
      }
    });
  } else {
    message.message('Bitte füllen Sie Namen  aus', 'danger');
  }
}

function deleteWare () {
  let _this = $(this);
  let row = _this.attr('data-id');
  let fgID = _this.attr('data-fgID');
  $(this).confirmation({
    title: 'Soll der Datensatz gelöscht werden?',
    placement: 'right',
    btnOkLabel: 'Ja',
    btnCancelLabel: 'Nein'
  }).on('confirmed.bs.confirmation', function (evt) {
    if (fgID !== '') {
      $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
          'modus': 'deleteWare',
          'fgID': fgID,
          'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
          if (data.error === false) {
            message.message('Der Eintrag wurde erfolgreich gelöscht', 'success');
            $('#waren-list-' + row).remove();
          } else {
            message.message('Beim Löschen ist ein Fehler aufgetreten', 'danger');
          }
        }
      });
    } else {
      $('#waren-list-' + row).remove();
    }
  });
}

function setNotSaved () {
  let _this = $('#waren-list-' + $(this).attr('data-id'));
  if (!_this.hasClass('not-saved')) {
    _this.addClass('not-saved');
  }
}

function initListeEvents () {
  let _saved = $('.checkSavedWare');
  let _saveWare = $('.saveWare');
  let _deleteWare = $('.deleteWare');
  _saved.off();
  _saveWare.off();
  _deleteWare.off();
  _saveWare.on('click', saveWare);
  _deleteWare.on('click', deleteWare);
  _saved.on('change', setNotSaved).on('blur', setNotSaved);
  autosize(document.querySelectorAll('textarea.is--autosize'));
}

export default function init () {
  $(document).ready(function () {
    initListeEvents();
    $('#addWare').on('click', addWare);
  });
}
