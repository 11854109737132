import $ from 'jquery'
import * as message from './message'

export default function kdzSubmit (options, callback) {
  let defaultSettings = {
    element: '.submitAjax'
  }
  let config = $.extend(defaultSettings, options)
  $(document.body).on('click', config.element, function (evt) {
    let _this = $(this)
    _this.attr('disabled',true)
    let _form = $('#' + _this.attr('data-form'))
    let error = false
    $('#' + _this.attr('data-form') +' *').filter(':input').each(function() {
        if ($(this).attr('required')) {
          if ($(this).val() === '') {
            $(this).closest('.form-group').addClass('has-error')
            error = true
          } else {
            $(this).closest('.form-group').removeClass('has-error')
          }
        }
    })

    if(error) {
     message.message('Bitte füllen Sie alle Rot makierten Felder aus!', 'danger')
      _this.attr('disabled',false)
    } else {
      $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: _form.serializeArray(),
        success: function (data) {

          if(data.error === true) {
            if(data.errorMessage && data.errorMessage !== '') {
              message.message(data.errorMessage, 'danger')
              _this.attr('disabled',false)
            } else {
              message.message('Beim Speichern ist ein Fehler aufgetreten, bitte versuchen Sie es noch einmal!', 'danger')
              _this.attr('disabled',false)
            }

          } else {
            if(data.link !== '') {
              window.location.href = data.link
              setTimeout(function(){window.location.reload(true);},500)
            } else {
              message.message('Der Datensatz wurde erfolgreich gespeichert!', 'success')
              _this.attr('disabled',false)
            }
          }
          callback(data, _form)
        },
        complete: function () {
          _this.attr('disabled',false)
        }
      })
    }
  })
}
