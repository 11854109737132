import $ from 'jquery'
import * as message from './message'

export default function kdzSaveField (el, settings) {
  let defaultSettings = {
    modulPfad: '',
    modus: 'saveField'
  }
  let config = $.extend(defaultSettings, settings)
  $(document.body).on('click', el, function (evt) {
    config = $.extend(config, $(this).data())

    config.content = $('#' + config.element).val()
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: config,
      success: function (data) {
        if(data.error === true) {
          message.message('Beim Speichern ist ein Fehler aufgetreten, bitte versuchen Sie es noch einmal!', 'danger')
        } else {
          message.message('Der Inhalt wurde erfolgreich gespeichert!', 'success')
        }
      }
    })
  })
}
