import $ from 'jquery'
import kdzDeleteRow from './deleteRow'
import kdzSubmit from './forms'
import kdzModalLoader from './modal'

export default function kdzTermin () {
  kdzModalLoader('.loadTermin')
  kdzSubmit({
    element: '.submitTermin'
  }, function (data, _form) {
    if($('.calendar-content').length) {
      console.log($('ul[data-time="' + data.time + '"][data-user="' + data.userID + '"]'))
      if(data.modus === 'new') {
        $('ul[data-time="' + data.time + '"][data-user="' + data.userID + '"]').append(data.htmlOut)
      } else {
        $('li[data-uxid="' + data.contentID + '"]').remove()
        $('ul[data-time="' + data.time + '"][data-user="' + data.userID + '"]').append(data.htmlOut)
      }

      kdzDeleteRow()
    } else if($('#calender').length) {
      $('#calender').fullCalendar('removeEventSource', 'eventTermin')
      $('#calender').fullCalendar('addEventSource', {
        id: 'eventTermin',
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
          modulPfad: 'plugins/mod_termin/',
          modus: 'getTermine'
        },
        color: '#605ca8',   // a non-ajax option
        textColor: 'white' // a non-ajax option
      })
    }
    var modal = _form.closest('.modal')
    modal.modal('hide')
    setTimeout(function () {
      modal.remove()
    }, 500);

  })
}