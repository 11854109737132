import $ from 'jquery'
import * as message from '../modules/message'

let modulPfadPro = 'plugins/mod_planung/projekte/'

function saveNotiz () {
  let pnUser = $('#pnUser')
  let pnTitel = $('#pnTitel')
  let pnText = $('#pnText')
  let pnPROID_FK = $('#pnPROID_FK')
  let modus = 'saveNotiz'
  let submitData = 'modus=' + modus +
    '&pnUser=' + pnUser.val() +
    '&pnTitel=' + pnTitel.val() +
    '&pnText=' + pnText.val() +
    '&pnPROID_FK=' + pnPROID_FK.val() +
    '&modus=' + modus +
    '&modulPfad=' + modulPfadPro
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: submitData,
    success: function (data) {
      $('#comment-list').html(data.html)
    }
  })
}


export default function init () {
  $(document).ready(function () {
    $(document.body).on('click', '#saveNotiz', saveNotiz)
  })
}